/*
 * _first-section.scss
 */

/* _first-section config */

/* _first-section default */
@include default {
  .first-section {
    z-index: $z--first-section;
    transition: opacity 2s ease 0.25s;
    padding: 7rem 0 0 15rem;
    color: $color--white;
    height: auto;

    .paragraph,
    .first-section__paragraphs-lead-1 {
      transition: color 2s ease;
      color: $color--white;
    }

    .paragraph { margin-bottom: 2.25rem; }
  }

  .first-section__tickboxes.tickboxes {
    width: 19.6em;
    top: 2em;

    li:last-child svg { width: 250%; }
  }

  .first-section__paragraphs .paragraph { max-width: 16.5rem; }

  .first-section__paragraphs-lead-wrapper.paragraph {
    margin-bottom: 0;
    white-space: nowrap;
    max-width: none;
  }

  .first-section__dim-paragraph {
    transition: opacity 3s ease;
    opacity: 0.25;

    &--is-visible { opacity: 1; }
  }

  .first-section__paragraphs-lead { position: relative; }

  .first-section__initial-paragraphs {
    max-width: 21.5em;

    .paragraph { max-width: none; }
  }

  .first-section__paragraphs-lead.paragraph {
    transition: color 2s ease;
    margin-bottom: 0;
    white-space: nowrap;
    max-width: none;
  }

  .first-section__paragraphs-lead-1,
  .first-section__paragraphs-lead-2 { transition: color 3s ease; }

  .first-section__smiley {
    position: absolute;
    left: 200%;
  }
}

/* _first-section medium-and-above */
@include medium-and-above {

}

/* _first-section large-and-above */
@include large-and-above {

}
