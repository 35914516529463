/*
 * _third-section.scss
 */

/* _third-section config */

/* _third-section default */
@include default {
  .third-section {
    position: relative;
    z-index: $z--third-section;
    flex-direction: column;
    top: 44.75rem; // -9.3
    left: 360rem; // Orig 260
    min-width: 200vw;
    height: auto;
    padding: 50.9rem 0 0 30rem;
  }

  .third-section__paragraphs {
    position: relative;
    z-index: 3;
    max-width: 18rem;
  }

  .third-section__last-paragraphs-container { position: relative; }

  .third-section__canvas {
    display: flex;
    align-items: center;
    width: 35rem;
    height: 25rem;
    margin-top: 2.5rem;
  }

  .third-section__canvas-container {
    position: relative;
    z-index: 2;
    top: -6.25rem;
    left: -10rem;
    width: 100%;
    height: 100%;
    margin-left: 1rem;

    canvas {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .third-section__canvas-aside {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;

    small {
      display: block;
      margin-bottom: 0.75rem;
      color: $color--white;
    }
  }

  .third-section__arrow {
    transform: scaleX(-1) rotate(100deg);
    position: absolute;
    bottom: -23vw;
    left: 72vw;
    width: 26.5vw;
    height: 30vw;
    margin-left: -5rem;
  }
}

/* _third-section medium-and-above */
@include medium-and-above {

}

/* _third-section large-and-above */
@include large-and-above {

}
