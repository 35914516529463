/*
 * _sections.scss
 */

/* _sections config */

/* _sections default */
@include default {
  .section {
    position: absolute;
    display: flex;
    height: 100vh;
  }
}

/* _sections medium-and-above */
@include medium-and-above {

}

/* _sections large-and-above */
@include large-and-above {

}
