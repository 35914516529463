/*
 * _fifth-section.scss
 */

/* _fifth-section config */

/* _fifth-section default */
@include default {
  .fifth-section {
    position: relative;
    z-index: $z--fifth-section;
    flex-direction: column;
    top: 51.7rem;
    left: 490rem;
    min-width: 200vw;
    height: auto;
    padding-left: 20rem;
  }

  .fifth-section__paragraphs {
    position: relative;
    z-index: 3;
    max-width: 23rem;
    margin-top: 9.7rem;

    .paragraph { margin-bottom: 1.5rem; }
  }

  .fifth-section__ending {
    margin-top: 16rem;

    .paragraph a:after { background: currentColor; }

    .paragraph {
      transition: opacity 1s ease;
      margin-bottom: 1rem;
      max-width: 10rem;
      opacity: 0.25;

      $transition-delay-base: 0.25s;
      $transition-delay: 1s;
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          transition-delay: $transition-delay-base + ($transition-delay * ($i - 1));
        }
      }

      &:last-child { margin-bottom: 0; }
    }

    &--is-visible .paragraph { opacity: 1; }
  }
}

/* _fifth-section medium-and-above */
@include medium-and-above {

}

/* _fifth-section large-and-above */
@include large-and-above {

}
