/*
 * _logo.scss
 */

/* _logo config */

/* _logo default */
@include default {
  .logo {
    transform: rotate(90deg);
    transform-origin: 0% 0%;
    position: fixed;
    z-index: 9001;
    top: 2.25rem;
    left: 2.9rem;
    height: 0.51rem;
    width: 3.8rem;

    .html--is-mobile & {
      top: 25px;
      left: 29px;
      width: 76px;
      height: 10px;
    }
  }
}

/* _logo medium-and-above */
@include medium-and-above {

}

/* _logo large-and-above */
@include large-and-above {
  .html--is-mobile .logo {
    top: 2.25rem;
    left: 2.9rem;
    height: 0.51rem;
    width: 3.8rem;
  }
}
