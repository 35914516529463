/*
 * _mobile.scss
 */

/* _mobile config */

/* _mobile default */
@include default {
  .mobile { display: none; }

  .mobile-container {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 150px 2rem 3rem 3rem;
  }

  .mobile-container section {
    display: block;
    width: 100%;
  }

  .mobile-container .paragraph {
    font-size: 1.276rem;
    letter-spacing: -.02em;
    line-height: 1.3;
    padding-bottom: 1.75em;
  }

  .mobile-container a.underscore:after {
    height: 0.125rem;
    background: $color--white;
  }
}

/* _mobile medium-and-above */
@include medium-and-above {

}

/* _mobile large-and-above */
@include large-and-above {
  .mobile-container {
    max-width: 29rem;
    padding: 1.25rem;
    margin: 8rem auto;
  }
}
