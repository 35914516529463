/*
 * style.scss
 *
 * This file contain media-query independent imports, other imports that ARE
 * dependent on media-queries are imported within media-query-dependants.scss
 */

@import 'base/variables';
@import 'helpers/mixins';
@import 'helpers/utils';
@import 'vendor/normalize';
@import 'vendor/scrollpath';
@import 'base/base';
@import 'base/fonts';

$layout-size: default;
@import 'media-query-dependants';

$layout-size: small-only;
@media #{$breakpoint--small-only} {
  @import 'media-query-dependants';
}

$layout-size: medium-only;
@media #{$breakpoint--medium-only} {
  @import 'media-query-dependants';
}

$layout-size: medium-and-above;
@media #{$breakpoint--medium-and-above} {
  @import 'media-query-dependants';
}

$layout-size: large-and-above;
@media #{$breakpoint--large-and-above} {
  @import 'media-query-dependants';
}
