/*
 * _utils.scss
 */

.visually-hidden { @include visually-hidden; }

.fade-in { animation: fadeIn ease-in .8s forwards; }

.color-grey-blue { color: $color--grey-blue; }
.color-black { color: $color--black; }

.bg-color--red { background: $color--red; }
.bg-color--purple { background: $color--purple; }
.bg-color--blue-lagoon { background: $color--blue-lagoon; }
.bg-color--blue { background: $color--blue; }
.bg-color--grey-blue { background: $color--grey-blue; }
.bg-color--white { background: $color--white; }

.m-b { margin-bottom: 1rem; }


.emoji {
  position: relative;
  text-shadow: 0px 0px 10px #000;

  &:before {
    content: attr(data-emoji);
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: -1;
    font-size: 1.5rem;
    line-height: 1;
    text-shadow: none;
    opacity: 0.3;
  }
}
