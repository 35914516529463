/*
 * _fourth-section.scss
 */

/* _fourth-section config */

/* _fourth-section default */
@include default {
  .fourth-section {
    z-index: $z--fourth-section;
    flex-direction: column;
    top: 125.5rem;
    left: 403rem;
    min-width: 175vw;
    padding: 12.95rem 0 0 17rem;

    .paragraph {
      transform: translate3d(0, 0, 0);
      position: relative;
      margin-bottom: 1.5rem;
    }
  }

  .fourth-section__paragraphs {
    position: relative;
    z-index: 1;

    .paragraph { max-width: 18rem; }
  }

  .fourth-section__last-paragraphs-container { position: relative; }

  .fourth-section__penrose {
    position: absolute;
    top: 17rem;
    left: 30rem;
    width: 30vw;
    height: 30vh;
    color: darken($color--grey, 10%);
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

/* _fourth-section medium-and-above */
@include medium-and-above {

}

/* _fourth-section large-and-above */
@include large-and-above {

}
