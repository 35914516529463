/*
 * _scrollpath.scss
 */

/* _scrollpath config */

/* _scrollpath default */
@include default {
  .scrollpath-outer-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }

  //.scroll-container { transition: transform 50ms ease-out; }
}

/* _scrollpath medium-and-above */
@include medium-and-above {

}

/* _scrollpath large-and-above */
@include large-and-above {

}
