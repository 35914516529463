/* _fonts.scss */

@font-face {
  font-family: 'Gerstner';
  src: url('/fonts/gerstner-bold.eot');
  src:
    url('/fonts/gerstner-bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gerstner-bold.woff2') format('woff2'),
    url('/fonts/gerstner-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gerstner';
  src: url('/fonts/gerstner-regular.eot');
  src:
    url('/fonts/gerstner-regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gerstner-regular.woff2') format('woff2'),
    url('/fonts/gerstner-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gerstner';
  src: url('/fonts/gerstner-light.eot');
  src:
    url('/fonts/gerstner-light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/gerstner-light.woff2') format('woff2'),
    url('/fonts/gerstner-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
