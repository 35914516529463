/*
 * _tickboxes.scss
 */

/* _tickboxes config */
@keyframes reveal-path-animation {
  to { stroke-dashoffset: 0; }
}

/* _tickboxes default */
@include default {
  .tickboxes {
    @include font-size(0.825rem);
    display: block;
    font-family: $font--family-mono;
    color: $color--grey;
    margin: 0 0 1.5rem 0;
    white-space: initial;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      margin: 0 1.5em 0.25em 0;
    }

    li:last-child {
      color: $color--white;
      margin-right: 0;

      svg {
        width: 400%;
        height: 400%;
      }
    }

    svg {
      @include center;
      position: absolute;
      z-index: 1;
      width: 125%;
      height: 125%;
    }
  }
}

/* _tickboxes medium-and-above */
@include medium-and-above {

}

/* _tickboxes large-and-above */
@include large-and-above {
  .tickboxes {
    margin: -100% 0.25rem 0 1.25rem;
    top: 1.8em;
    display: inline-block;
    position: relative;
  }
}
