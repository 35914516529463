/*
 * _hover-image.scss
 */

/* _hover-image config */

/* _hover-image default */
@include default {
  .hover-image {
    position: relative;

    &:hover {
      z-index: 9999;
      cursor: none;

      .hover-image__text {
        transition: opacity 0.2s ease !important;
        opacity: 1 !important;
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      }
    }

    .hover-image__text {
      position: relative;
      z-index: $z--hover-image-text;
    }
  }

  .hover-image__text {
    transition:
      opacity 0.2s ease 0.1s,
      color 2s ease !important;
    position: relative;
  }

  .hover-image__img {
    transform: translate3d(-25%, -50%, 0);
    transition: opacity 0.2s ease;
    position: fixed;
    display: block;
    max-width: 30vw;
    max-height: 60vh;
    opacity: 0;
    pointer-events: none;

    &--is-visible { opacity: 1; }
  }

  .hover-image__img--office {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

/* _hover-image medium-and-above */
@include medium-and-above {

}

/* _hover-image large-and-above */
@include large-and-above {

}
