/*
 * _second-section.scss
 */

/* _second-section config */

/* _second-section default */
@include default {
  .second-section {
    z-index: $z--second-section;
    top: 22rem; // -9.85
    left: 285rem; // +35
    height: auto;
    min-width: 100vw;
    padding: 11.3rem 0 0 25rem;

    .paragraph {
      transform: translate3d(0, 0, 0);
      margin-bottom: 1rem;

      &:last-child { margin-bottom: 0; }
    }
  }

  .second-section__paragraphs {
    .paragraph { max-width: 24rem; }

    .paragraph--large:nth-child(1) { white-space: nowrap; }

    .paragraph--large:nth-child(2) { margin-bottom: 2rem; }
  }

  .second-section__last-paragraphs-container {
    position: relative;

    .paragraph {
      position: relative;
      z-index: 1;
      max-width: 17.5rem;
    }

    .paragraph:nth-child(3) { margin-bottom: 0; }
  }

  .second-section__paragraph-lead-wrapper.paragraph {
    margin-bottom: 0;
    white-space: nowrap;
    max-width: none;
  }

  .second-section__paragraph-lead.paragraph {
    margin-bottom: 0;
    white-space: nowrap;
    max-width: none;
  }

  .second-section__herman-globe-aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: -10vw;
    height: 100%;
    pointer-events: none;

    small {
      display: block;
      margin-bottom: 0.75rem;
      color: $color--grey;
    }
  }

  .second-section__herman-globe-wrapper {
    position: relative;
  }

  .second-section__herman-globe {
    position: relative;
    top: -0.4rem;
    width: 40vw;
    height: 40vw;
    margin-left: -9vw;

    canvas {
      opacity: 0.6;
      cursor: grab;

      &:active { cursor: grabbing; }
    }

    canvas + div { display: none; }

    .we-pp-wrapper {
      border-radius: 0;
      box-shadow: none;
      color: $color--black;
    }

    .we-pp-content {
      font-family: $font--base-family;
      position: relative;
      top: -3px;
      font-size: 0.5rem;
      text-align: center;
      width: 100%;
      margin: 19px 0 10px;
    }

    .we-pp-tip { box-shadow: none; }

    & > div {
      transition: opacity 1s ease;
      opacity: 0.25;
    }

    .we-pm-icon {

      image-rendering: -webkit-optimize-contrast;
      background-size: 60%;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover > div { opacity: 1; }
  }
}

/* _second-section medium-and-above */
@include medium-and-above {

}

/* _second-section large-and-above */
@include large-and-above {

}
