/*
 * _figure.scss
 */

/* _figure config */

/* _figure default */
@include default {
  .figure {
    display: flex;
    flex-direction: column;
  }

  .figure__image {
    width: 100%;
    padding-bottom: 100%;
    background: $color--white;
  }

  .figcaption {
    @include font-size(0.425rem);
    margin-top: 0.6rem;
    font-family: $font--family-mono;
    text-align: center;
  }
}

/* _figure medium-and-above */
@include medium-and-above {

}

/* _figure large-and-above */
@include large-and-above {

}
