/*
 * _sections.scss
 */

/* _sections config */

/* _sections default */
@include default {
  .image-slider {
    position: relative;
  }

  .image-slider__slide {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    &--1 {
      position: relative;
      z-index: 3;
    }

    &--2 {
      z-index: 2;
      top: 15%;
      left: -10%;
    }

    &--3 {
      z-index: 1;
      top: 30%;
      left: -20%;
    }
  }
}

/* _sections medium-and-above */
@include medium-and-above {

}

/* _sections large-and-above */
@include large-and-above {

}
