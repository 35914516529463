/*
 * _scroll-waypoint.scss
 */

/* _scroll-waypoint config */

/* _scroll-waypoint default */
@include default {
  .scroll-waypoint {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    pointer-events: none;

    // Uncomment this for debugging
    // width: 20px;
    // height: 20px;
    // border-radius: 10px;
    // background: red;
    // z-index: 9999;
  }

  .scroll-waypoint--first-section-1 { }

  .scroll-waypoint--first-section-2 {
    top: -44vh;
    left: -70%;
  }

  .scroll-waypoint--second-section-1 {
    left: 10%;
  }

  .scroll-waypoint--second-section-2 {
    top: 63%;
    left: 10%;
  }

  .scroll-waypoint--third-section-1 {
    top: 41%;
    left: 10%;
  }

  .scroll-waypoint--third-section-2 {
    top: auto;
    left: 10%;
    bottom: 4%;
  }

  .scroll-waypoint--fourth-section-1 { top: 33.5%; }

  .scroll-waypoint--fourth-section-2 {
    top: 40%;
    left: auto;
    right: 0;
  }

  .scroll-waypoint--fifth-section-1 { top: -7.5%; }

  .scroll-waypoint--fifth-section-2 { top: 57%; }
}

/* _scroll-waypoint medium-and-above */
@include medium-and-above {

}

/* _scroll-waypoint large-and-above */
@include large-and-above {

}
