/*
 * _base.scss
 *
 * This is a good place to put 'opinionated' resets.
 */

html {
  box-sizing: border-box;

  &.html--is-desktop,
  &.html--is-desktop body { overflow: hidden; }

  &.html--is-mobile .mobile { display: block; }
}

body {
  // Set a standardized guaranteed line-height of 1, since the default
  // interpreted value of 'normal' is browser specific
  // http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation
  line-height: 1;
  color: $color--white;
  background: $color--black;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  &.body--hover-image {
    .tickboxes,
    .hover-image__text,
    .third-section__canvas {
      transition: opacity 0.2s ease !important;
      opacity: 0;
      z-index: 0;
    }
  }
}

*,
*:before,
*:after { box-sizing: inherit; }

// Prevent overflowing of images in fluid containers
img { max-width: 100%; }

a {
  text-decoration: none;
  color: $color--white;
}

h1, h2 { font-style: normal; }

p,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul, ol { list-style: none; }
