/*
 * _typography.scss
 */

/* _typography config */

/* _typography default */
@include default {
  html {
    @include base-font(6.6666667vw);

    &.html--is-mobile { @include base-font(5.8vw); }
  }

  .paragraph { line-height: 1.575; }

  .paragraph--large {
    @include font-size(1.876rem);
    letter-spacing: -0.02em;
    line-height: 1.3;
  }

  .paragraph--larger {
    @include font-size(2.8rem);
    letter-spacing: -0.0125em;
    line-height: 1.1;
  }

  .paragraph--bold { font-weight: 700; }

  .small {
    @include font-size(0.55rem);
    font-family: $font--family-mono;
    color: $color--grey-blue;
  }

  .reveal-paragraph {
    transition: color 1.5s ease;
    color: $color--grey;

    &--is-revealed { color: $color--white; }
  }

  .underscore {
    position: relative;

    &:after {
      transition: background 1s ease;
      display: block;
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1rem;
      background: #333;
    }
  }

  .nowrap { white-space: nowrap; }
}

/* _typography small-only */
@include small-only {

}

/* _typography medium-only */
@include medium-only {
  .paragraph--large {
    @include font-size(1.876rem);
    letter-spacing: -0.02em;
    line-height: 1.4;
  }
}

/* _typography medium-and-above */
@include medium-and-above {
}

/* _typography large-and-above */
@include large-and-above {
  html { @include base-font(1.6666667vw); }
}
